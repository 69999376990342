<template>
  <div class="Resend-email_root">
    <h2>Please verify your email address</h2>
    <p>
      Your email address: <span>{{ userEmail }}</span>
    </p>
    <Button :disabled="disabled" brightBtn size="large" text="Send new verification email" @click="resendEmailVerificationLink" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Button from '@/components/_common/FormElements/Button/Button';
export default {
  data: () => ({
    userEmail: '',
    disabled: false,
  }),
  components: {
    Button,
  },
  methods: {
    ...mapActions('user', ['resendVerifyUserEmail']),
    emailValidate() {
      if (this.$route.params && this.$route.params.email) {
        this.userEmail = this.$route.params.email;
      } else {
        this.$router.push({ name: 'login' });
      }
    },
    resendEmailVerificationLink() {
      this.resendVerifyUserEmail(this.userEmail);
    },
  },
  mounted() {
    this.emailValidate();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_colors.scss';
.Resend-email_root {
  h2 {
    font-size: 34px;
    line-height: 144%;
    color: $primaryText;
  }
  p {
    margin: 20px 0 0 0;
    color: $primaryText;
    font-size: 14px;
    line-height: 16px;
    span {
      color: $primaryLanding;
    }
  }
  .Button_button {
    margin: 75px 0 0 0;
    max-width: 400px;
    width: 100%;
  }
}
</style>
